/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import auth from '@/middleware/auth';
// import admin from '@/middleware/admin';
import guest from '@/middleware/guest';
import hasCart from '@/middleware/hasCart';
import middlewarePipeline from '@/router/middlewarePipeline';
import store from '@/store/index.js';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: 'home' */ '@/views/Home'),
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: 'home' */ '@/views/Home'),
  },
  {
    path: '/products',
    name: 'Menus',
    component: () => import(/* webpackChunkName: 'products' */ '@/views/products/Products'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: 'blog' */ '@/views/blog/Blog'),
    props: (route) => ({ page: route.query.page ?? '1' }),
  },
  {
    path: '/blog/post/:id',
    name: 'BlogPost',
    component: () => import(/* webpackChunkName: 'blog-post' */ '@/views/blog/BlogPost'),
  },
  {
    path: '/blog/category/:category',
    name: 'BlogCategory',
    component: () => import(/* webpackChunkName: 'blog-category' */ '@/views/blog/BlogCategory'),
    props: (route) => ({ category: route.params.category, page: route.query.page ?? '1' }),
  },
  {
    path: '/blog/category/:category/post/:id',
    name: 'CategoryBlogPost',
    component: () => import(/* webpackChunkName: 'category-blog-post' */ '@/views/blog/BlogPost'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: 'login' */ '@/views/auth/Login'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { middleware: [auth] },
    component: () => import(/* webpackChunkName: 'dashboard' */ '@/views/customer/Home'),
  },
  {
    path: '/register',
    name: 'Register',
    meta: { middleware: [guest] },
    component: () => import(/* webpackChunkName: 'register' */ '@/views/auth/Register'),
  },
  {
    path: '/thanks',
    name: 'Thanks',
    meta: { middleware: [auth] },
    component: () => import(/* webpackChunkName: 'thanks' */ '@/views/Thanks'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    meta: { middleware: [hasCart, auth] },
    component: () => import(/* webpackChunkName: 'checkout' */ '@/views/Checkout'),
  },
  {
    path: '/orders/summary',
    name: 'ConfirmOrder',
    meta: { middleware: [hasCart, auth] },
    component: () => import(/* webpackChunkName: 'order-summary' */ '@/views/ConfirmOrder'),
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: { middleware: [auth] },
    component: () => import(/* webpackChunkName: 'pending-orders' */ '@/views/customer/orders/Index'),
  },
  {
    path: '/orders/:id',
    name: 'ViewOrder',
    meta: { middleware: [auth] },
    component: () => import(/* webpackChunkName: 'view-order' */ '@/views/customer/orders/Order'),
  },
  // {
  //   path: '/orders/:status/:id',
  //   name: 'ViewOrder',
  //   meta: { middleware: [auth] },
  //   component: () => import(/* webpackChunkName: 'view-order' */ '@/views/customer/orders/Order'),
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: 'about' */ '@/views/About'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { middleware: [auth] },
    component: () => import(/* webpackChunkName: 'profile' */ '@/views/customer/profile/Profile'),
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import(/* webpackChunkName: 'demo' */ '@/views/Demo'),
  },
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: '404' */ '@/views/404'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  },
});

router.beforeEach((to, from, next) => {
    // store.state.loading = true;
    if (store.getters['auth/authUser'] && store.getters['cart/cart']) {
      /** Navigate to next if middleware is not applied */
      if (!to.meta.middleware) {
        return next()
      }
      const middleware = to.meta.middleware;
      const context = {
        to,
        from,
        next,
        store, //     | You can also pass store as an argument
        router, //    | to the middleware
      }
      return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
      });
    } else {
    store.dispatch('auth/getAuthUser').then(() => {
      store.dispatch('cart/getCart').then(() => {
        /** Navigate to next if middleware is not applied */
        if (!to.meta.middleware) {
          return next()
        }
        const middleware = to.meta.middleware;
        const context = {
          to,
          from,
          next,
          store, //     | You can also pass store as an argument
          router, //    | to the middleware
        }
        return middleware[0]({
          ...context,
          next: middlewarePipeline(context, middleware, 1)
        });
      }).catch((error) => {
        console.error(`Get Cart Error from router: ${error}`);
      });
    })
    .catch((error) => {
      console.error(`Get Auth User Error from router: ${error}`);
    });
  }
});

export default router;
