import { createStore, createLogger } from 'vuex';
import auth from './modules/auth';
import order from './modules/order.js';
import cart from './modules/cart';
import wishlist from './modules/wishlist';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    order,
    cart,
    wishlist,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
