import router from '@/router/index';
import {
  getError,
  toggleContentEditable,
  saveToLocalStorage,
  clearLocalStorageData,
} from '@/utils/helpers';
import OrderService from '@/services/OrderService';

export const state = {
  orders: null,
  order: null,
  customer: {},
  billingInformation: {},
  deliveryAddress: null,
  htmlFormattedAddress: null,
  isEditingBillingInformation: false,
  isEditingDeliveryAddress: false,
  meta: null,
  links: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders;
  },
  SET_ORDER(state, order) {
    state.order = order;
  },
  SET_CUSTOMER(state, customer) {
    state.customer = { ...customer };
  },
  SET_FORMATTED_DELIVERY_ADDRESS(state, formatted) {
    state.htmlFormattedAddress = formatted;
  },
  SET_IS_EDITING_BILLING_INFORMATION(state, isEditingBillingInformation) {
    state.isEditingBillingInformation = isEditingBillingInformation;
  },
  SET_IS_EDITING_DELIVERY_ADDRESS(state, isEditingDeliveryAddress) {
    state.isEditingDeliveryAddress = isEditingDeliveryAddress;
  },
  SET_DELIVERY_ADDRESS(state, deliveryAddress) {
    state.deliveryAddress = deliveryAddress;
  },
  SET_BILLING_INFORMATION(state, billingInformation) {
    state.billingInformation = billingInformation;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async getOrders({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await OrderService.getOrders();
      const data = response.data;
      const items = data.data.map((order) => {
        return {
          id: order.id,
          ...order.attributes,
          customer: {
            id: order.relationships.customer.id,
            ...order.relationships.customer.attributes,
          },
          items: order.relationships.items.map((item) => {
            return {
              id: item.id,
              ...item.attributes,
              links: item.links,
            };
          }),
        };
      });
      commit('SET_ORDERS', { items: items, meta: data.meta });
      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ORDERS', []);
      commit('SET_ERROR', getError(error));
    }
  },

  async getOrdersByStatus({ commit }, { status }) {
    commit('SET_LOADING', true);
    try {
      const response = await OrderService.getOrdersByStatus(status);
      const data = response.data;
      const items = data.data.map((order) => {
        return {
          rowId: order.rowId,
          ...order.attributes,
          customer: {
            id: order.relationships.customer.id,
            ...order.relationships.customer.attributes,
          },
          items: order.relationships.items.map((item) => {
            return {
              id: item.id,
              ...item.attributes,
              links: item.links,
            };
          }),
        };
      });
      commit('SET_ORDERS', { items: items, meta: data.meta });
      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ORDERS', []);
      commit('SET_ERROR', getError(error));
    }
  },

  async getOrder({ commit }, { id }) {
    // TODO:  pass status too later
    commit('SET_LOADING', true);
    try {
      const response = await OrderService.getOrder(id);
      const data = response.data;
      const order = {
        id: data.id,
        ...data.attributes,
        customer: {
          id: data.relationships.customer.id,
          ...data.relationships.customer.attributes,
        },
        items: data.relationships.items.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
            links: item.links,
          };
        }),
      };
      commit('SET_ORDER', order);
      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },

  async cancelOrder({ commit }, { id }) {
    // TODO:  pass status too later
    commit('SET_LOADING', true);
    console.log(id);
    try {
      await OrderService.cancelOrder(id);
      commit('SET_LOADING', false);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },

  async confirmOrder({ commit, state }) {
    commit('SET_LOADING');
    try {
      const payload = {
        ...state.billingInformation,
        delivery_address: state.deliveryAddress.use_new_address
          ? state.deliveryAddress.new_address
          : state.deliveryAddress.current_address,
      };
      await OrderService.createOrder(payload);
      commit('SET_LOADING', false);
      await commit('cart/SET_CART', null, { root: true });
      clearLocalStorageData([
        'billingInformation',
        'deliveryAddress',
        'orderContactInfo',
      ]);
      router.push({ path: '/thanks' });
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ORDERS', []);
      commit('SET_ERROR', getError(error));
    }
  },

  formatAddressAsHtml({ commit, state }) {
    let address = '';
    let current_customer = state.customer;
    if (!Object.keys(current_customer).length) {
      const contactInfo = window.localStorage.getItem('orderContactInfo');
      const deliveryAddress = window.localStorage.getItem('deliveryAddress');
      if (!Object.keys(contactInfo).length && !Object.keys(deliveryAddress).length)
        return null;
      current_customer = JSON.parse(deliveryAddress) || JSON.parse(contactInfo);
    }
    address = !current_customer.use_new_address
      ? current_customer.current_address
      : current_customer.new_address;

    const addressArr = address.split(',');
    const formatted = addressArr.map((item) => {
      return `<p>${item}</p>`;
    });
    const billingInformation = window.localStorage.getItem('orderContactInfo') ?? {};
    commit('SET_BILLING_INFORMATION', JSON.parse(billingInformation));
    commit('SET_DELIVERY_ADDRESS', {
      current_address: current_customer.current_address,
      new_address: current_customer.new_address,
      use_new_address: current_customer.use_new_address,
    });
    commit('SET_FORMATTED_DELIVERY_ADDRESS', formatted.join(''));
  },

  toggleBillingInformation({ commit, state }, { elements }) {
    console.log(elements);
    toggleContentEditable(elements, true);
    commit('SET_IS_EDITING_BILLING_INFORMATION', !state.isEditingBillingInformation);
  },

  saveBillingInformation({ commit, state }, { elements }) {
    let billingInformation = {};
    for (let value of elements) {
      billingInformation[value.dataset.key] = value.innerText;
    }
    toggleContentEditable(elements, false);
    saveToLocalStorage({
      key: 'billingInformation',
      value: billingInformation,
    });
    commit('SET_IS_EDITING_BILLING_INFORMATION', !state.isEditingBillingInformation);
    commit('SET_BILLING_INFORMATION', billingInformation);
    // send to server
  },

  toggleEditingDeliveryAddress({ commit, state }, { elements }) {
    toggleContentEditable(elements, true);
    commit('SET_IS_EDITING_DELIVERY_ADDRESS', !state.isEditingDeliveryAddress);
  },

  saveDeliveryAddress({ commit, dispatch, state }, { elements }) {
    let deliveryAddress = {};
    for (let value of elements) {
      const addressArr = value.innerText.split(/\n+/);
      deliveryAddress.new_address = addressArr.join(',');
      deliveryAddress.use_new_address = true;
    }
    toggleContentEditable(elements, false);
    saveToLocalStorage({ key: 'deliveryAddress', value: deliveryAddress });
    commit('SET_IS_EDITING_DELIVERY_ADDRESS', !state.isEditingDeliveryAddress);
    dispatch('formatAddressAsHtml');
  },

  saveOrderDetails({ commit, dispatch }, { payload }) {
    commit('SET_LOADING', true);
    const billingInformation = {
      id: payload.id,
      first_name: payload.first_name,
      last_name: payload.last_name,
      email: payload.email,
      phone: payload.phone,
    };
    const deliveryAddress = {
      current_address: payload.current_address,
      new_address: payload.new_address,
      use_new_address: payload.use_new_address,
    };

    commit('SET_BILLING_INFORMATION', billingInformation);
    saveToLocalStorage({ key: 'deliveryAddress', value: deliveryAddress });
    saveToLocalStorage({ key: 'orderContactInfo', value: payload });
    commit('SET_CUSTOMER', payload);
    dispatch('formatAddressAsHtml');
    commit('SET_LOADING', false);
    router.push('/orders/summary');
  },
};

export const getters = {
  orders: (state) => state.orders,
  order: (state) => state.order,
  customer: (state) => {
    if (state.customer.length) {
      return state.customer;
    }
    const storageItem = window.localStorage.getItem('orderContactInfo');
    if (!storageItem) return {};
    return JSON.parse(storageItem);
  },
  billingInformation: (state) => {
    const storageItem = window.localStorage.getItem('billingInformation');
    return storageItem && storageItem !== 'undefined'
      ? JSON.parse(storageItem)
      : state.billingInformation;
  },
  htmlFormattedAddress: (state) => state.htmlFormattedAddress,
  isEditingBillingInformation: (state) => state.isEditingBillingInformation,
  isEditingDeliveryAddress: (state) => state.isEditingDeliveryAddress,
  loading: (state) => state.loading,
  error: (state) => state.error,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
