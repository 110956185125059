export default function hasCart({ from, next, store }) {
  if (store.getters['cart/cartIsEmpty']) {
    store.dispatch('cart/restoreCart').then(() => {
      if (store.getters['cart/cart']) next({ path: from.path });
      else next();
    });
  } else {
    next();
  }
}
