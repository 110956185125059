import axiosConfig from '@/utils/AxiosConfig';

export default {
  async getCart() {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get('/cart/wishlist');
  },
  async getItem(id) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get(`/cart/wishlist/${id}`);
  },
  async removeItem(rowId) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.delete(`/cart/wishlist/${rowId}`);
  },
  async emptyCart() {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.delete(`/cart/wishlist/destroy`);
  },
  async restoreCart() {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get(`/cart/wishlist/restore`);
  },

  async addToWishlist(payload) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.post(`/cart/wishlist/add-to-wishlist`, payload);
  },
};
