// import router from '../../router/index';
import { getError } from '../../utils/helpers';
import CartService from '../../services/CartService';

export const state = {
  showCart: false,
  cart: null,
  currentItemIndex: null,
  loading: false,
  error: null,
};

export const mutations = {
  SHOW_CART(state, showCart) {
    state.showCart = showCart;
  },
  SET_CART(state, cart) {
    state.cart = cart;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  async SET_CURRENT_ITEM_INDEX(state, index) {
    state.currentItemIndex = index;
    state.cart.items.splice(index, 1);
  },
  async SET_TOTAL(state, value = 0) {
    let total = value;
    let subtotal = 0;
    state.cart.items.forEach((element, index) => {
      subtotal = element.qty * element.price;
      state.cart.items[index].subtotal = subtotal;
      total += element.subtotal;
    });
    // TODO:  handle currency dynamically everywhere like here
    state.cart.total = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'GHS',
    }).format(total);
  },
  SET_ITEM_QUANTITY(state, data) {
    const cart = state.cart;
    let currentIndex = null;
    // get item from cart by rowId
    const item = cart.items.filter((item, index) => {
      if (item.rowId === data.rowId) {
        currentIndex = index;
        return item;
      }
    });
    item[0].qty = data.quantity;
    item[0].subtotal = data.subtotal;
    cart.items[currentIndex] = item[0];
    cart.total = data.total;
    state.cart = cart;
  },
};

export const actions = {
  toggleCart({ commit, state }) {
    commit('SHOW_CART', !state.showCart);
  },
  addToCart({ commit }, { product_id, quantity }) {
    commit('SET_LOADING', true);
    return CartService.addToCart({ product_id, quantity })
      .then((response) => {
        const data = response.data;
        const items = data.data.map((item) => {
          return {
            rowId: item.rowId,
            ...item.attributes,
          };
        });
        commit('SET_CART', { items: items, ...data.meta });
        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
        return { items: items, ...data.meta };
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      });
  },
  updateItemQuantity({ commit, state }, { rowId, quantity }) {
    commit('SET_LOADING', true);
    return CartService.updateItemQuantity(rowId, { quantity: quantity })
      .then((response) => {
        const data = response.data;

        commit('SET_ITEM_QUANTITY', data);
        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
        return state.cart;
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      });
  },
  async removeItem({ commit }, { index, rowId }) {
    commit('SET_LOADING', true);
    try {
      await commit('SET_CURRENT_ITEM_INDEX', index);
      await commit('SET_TOTAL', 0);
      const response = await CartService.removeItem(rowId);
      const data = response.data;
      const items = data.data.map((item) => {
        return {
          rowId: item.rowId,
          ...item.attributes,
        };
      });
      commit('SET_CART', { items: items, ...data.meta });
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);

      return { items: items, ...data.meta };
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  emptyCart({ commit }) {
    commit('SET_LOADING', true);
    return CartService.emptyCart()
      .then((response) => {
        const data = response.data;
        const items = data.data.map((item) => {
          return {
            rowId: item.rowId,
            ...item.attributes,
          };
        });
        commit('SET_CART', { items: items, ...data.meta });
        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
        return { items: items, ...data.meta };
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      });
  },
  getCart({ commit }) {
    commit('SET_LOADING', true);
    return CartService.getCart()
      .then((response) => {
        const data = response.data;
        const items = data.data.map((item) => {
          return {
            rowId: item.rowId,
            ...item.attributes,
          };
        });
        commit('SET_CART', { items: items, ...data.meta });
        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
        return { items: items, ...data.meta };
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      });
  },
  checkout({ commit, state }) {
    commit('SHOW_CART', !state.showCart);
    commit('SET_LOADING', true);
    return CartService.checkoutCart()
      .then((response) => {
        console.log(response.data);
        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      });
  },
  async restoreCart({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await CartService.restoreCart();
      console.log(response.data);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
};

export const getters = {
  cart: (state) => {
    return state.cart;
  },
  error: (state) => {
    return state.error;
  },
  loading: (state) => {
    return state.loading;
  },
  cartIsEmpty: (state) => {
    return state.cart ? state.cart.items.length === 0 : true;
  },
  currentItemIndex(state) {
    return state.currentItemIndex;
  },
  showCart: (state) => state.showCart,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
