import axiosConfig from '@/utils/AxiosConfig';

export default {
  async getOrders() {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get('/orders');
  },
  async getOrdersByStatus(status) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get(`/orders/${status}`);
  },
  async getOrder(id) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get(`/orders/${id}`);
  },
  async getOrderByStatus({ status, id }) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get(`/orders/${status}/${id}`);
  },
  async createOrder(payload) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.post(`/orders`, payload);
  },
  async confirmOrder(id) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.patch(`/orders/${id}/confirm`);
  },
  async cancelOrder(id) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.patch(`/orders/${id}/cancel`);
  },
  async deleteOrder(id) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.delete(`/orders/${id}`);
  },
};
