import axiosConfig from '@/utils/AxiosConfig';

export default {
  async getCart() {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get('/cart/shopping');
  },
  async getItem(id) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get(`/cart/shopping/${id}`);
  },
  async addToCart(payload) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.post(`/cart/shopping`, payload);
  },
  async updateItemQuantity(rowId, payload) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.patch(`/cart/shopping/update-quantity/${rowId}`, payload);
  },
  async removeItem(rowId) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.delete(`/cart/shopping/${rowId}`);
  },
  async emptyCart() {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.delete(`/cart/shopping/destroy`);
  },
  async saveCart() {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get(`/cart/shopping/save`);
  },
  async restoreCart() {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get(`/cart/shopping/restore`);
  },
  async checkoutCart() {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.api.get(`/cart/shopping/checkout`);
  },
};
