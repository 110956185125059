import router from '@/router';
import { getError } from '@/utils/helpers';
import AuthService from '@/services/AuthService';
// import * as cartActions from './cart';

export const state = {
  user: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  logout({ commit, dispatch }) {
    return AuthService.logout()
      .then(() => {
        commit('SET_USER', null);
        dispatch('setGuest', { value: 'isGuest' });
        if (router.currentRoute.name !== 'login') router.push({ path: '/login' });
      })
      .catch((error) => {
        commit('SET_ERROR', getError(error));
      });
  },
  async getAuthUser({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await AuthService.getAuthUser();
      await commit('SET_USER', response.data.data);
      commit('SET_LOADING', false);
      return response.data.data;
    } catch (error) {
      commit('SET_LOADING', false);
      // commit('SET_USER', null);
      commit('SET_ERROR', getError(error));
    }
  },
  async login({ commit, dispatch }, { email, password }) {
    commit('SET_LOADING', true);
    const payload = {
      email: email,
      password: password,
    };
    try {
      await AuthService.login(payload);
      const authUser = await dispatch('getAuthUser');

      commit('SET_LOADING', false);
      if (authUser) {
        await dispatch('cart/restoreCart', true, { root: true });
        await dispatch('setGuest', { value: 'isNotGuest' });
        router.push('/dashboard');
      } else {
        const error = Error('Unable to fetch user after login, check your API settings.');
        error.name = 'Fetch User';
        throw error;
      }
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  async performActionAfterLogin({ commit, dispatch }, { email, password }) {
    commit('SET_LOADING', true);
    const payload = {
      email: email,
      password: password,
    };
    try {
      await AuthService.login(payload);
      const authUser = await dispatch('getAuthUser');

      commit('SET_LOADING', false);
      if (authUser) {
        // cartActions.actions.restoreCart();
        dispatch('setGuest', { value: 'isNotGuest' });
      } else {
        const error = Error('Unable to fetch user after login, check your API settings.');
        error.name = 'Fetch User';
        throw error;
      }
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  setGuest(context, { value }) {
    window.localStorage.setItem('guest', value);
  },
};

export const getters = {
  authUser: (state) => {
    return state.user;
  },
  isAdmin: (state) => {
    return state.user ? state.user.isAdmin : false;
  },
  error: (state) => {
    return state.error;
  },
  loading: (state) => {
    return state.loading;
  },
  loggedIn: (state) => {
    return !!state.user;
  },
  guest: () => {
    const storageItem = window.localStorage.getItem('guest');
    if (!storageItem) return false;
    if (storageItem === 'isGuest') return true;
    if (storageItem === 'isNotGuest') return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
