// import router from '../../router/index';
import { getError } from '../../utils/helpers';
import WishlistService from '../../services/WishlistService';

export const state = {
  wishlist: null,
  currentItemIndex: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_WISHLIST(state, wishlist) {
    state.wishlist = wishlist;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  async SET_CURRENT_ITEM_INDEX(state, index) {
    state.currentItemIndex = index;
    state.wishlist.items.splice(index, 1);
  },
};

export const actions = {
  getCart({ commit }) {
    commit('SET_LOADING', true);
    return WishlistService.getCart()
      .then((response) => {
        const data = response.data;
        const items = data.data.map((item) => {
          return {
            rowId: item.rowId,
            ...item.attributes,
          };
        });
        commit('SET_WISHLIST', { items: items, ...data.meta });
        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
        return { items: items, ...data.meta };
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      });
  },
  addToWishlist({ commit }, { product_id }) {
    commit('SET_LOADING', true);
    return WishlistService.addToWishlist({ product_id })
      .then((response) => {
        const data = response.data;
        const items = data.data.map((item) => {
          return {
            rowId: item.rowId,
            ...item.attributes,
          };
        });
        commit('SET_WISHLIST', { items: items });

        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
        return { items: items };
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      });
  },
  async removeItem({ commit }, { index, rowId }) {
    commit('SET_LOADING', true);
    try {
      await commit('SET_CURRENT_ITEM_INDEX', index);
      const response = await WishlistService.removeItem(rowId);
      const data = response.data;
      const items = data.data.map((item) => {
        return {
          rowId: item.rowId,
          ...item.attributes,
        };
      });
      commit('SET_WISHLIST', { items: items, ...data.meta });
      commit('SET_LOADING', false);
      commit('SET_ERROR', null);

      return { items: items, ...data.meta };
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
  emptyCart({ commit }) {
    commit('SET_LOADING', true);
    return WishlistService.emptyCart()
      .then((response) => {
        const data = response.data;
        const items = data.data.map((item) => {
          return {
            rowId: item.rowId,
            ...item.attributes,
          };
        });
        commit('SET_WISHLIST', { items: items, ...data.meta });
        commit('SET_LOADING', false);
        commit('SET_ERROR', null);
        return { items: items, ...data.meta };
      })
      .catch((error) => {
        commit('SET_LOADING', false);
        commit('SET_ERROR', getError(error));
      });
  },
  async restoreCart({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await WishlistService.restoreCart();
      console.log(response.data);
    } catch (error) {
      commit('SET_LOADING', false);
      commit('SET_ERROR', getError(error));
    }
  },
};

export const getters = {
  wishlist: (state) => {
    return state.wishlist;
  },
  error: (state) => {
    return state.error;
  },
  loading: (state) => {
    return state.loading;
  },
  wishlistIsEmpty: (state) => {
    return state.wishlist ? state.wishlist.items.length === 0 : true;
  },
  currentItemIndex(state) {
    return state.currentItemIndex;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
