import axiosConfig from '@/utils/AxiosConfig';

export default {
  async login(payload) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.auth.post('/login', payload);
  },
  logout() {
    return axiosConfig.auth.post('/logout');
  },
  async forgotPassword(payload) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.auth.post('/forgot-password', payload);
  },
  getAuthUser() {
    return axiosConfig.api.get('/users/auth');
  },
  async resetPassword(payload) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.auth.post('/reset-password', payload);
  },
  updatePassword(payload) {
    return axiosConfig.auth.put('/user/password', payload);
  },
  async registerUser(payload) {
    await axiosConfig.auth.get('/sanctum/csrf-cookie');
    return axiosConfig.auth.post('/register', payload);
  },
  sendVerification(payload) {
    return axiosConfig.auth.post('/email/verification-notification', payload);
  },
  updateUser(payload) {
    return axiosConfig.auth.put('/user/profile-information', payload);
  },
};
